.mainContent {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    li {
      list-style: none;
      flex: 0 0 33.333333%;
      padding: 0 12px;
      margin-bottom: 24px;
      > span {
        background: #ffffff;
        box-shadow: 4px 4px 10px #f0f0f0;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        cursor: pointer;
        &:hover {
          background: #d5dfff;
        }
        > img {
          max-width: 100%;
          max-height: 40px;
        }
      }
    }
  }
}
.clientLogo {
  font-size: 35px;
  font-weight: bold;
  color: #0e121a;
}
