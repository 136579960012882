.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d2d9;
  > button {
    position: relative;
    top: -12px;
  }
}
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0e121a;
}
