.inner {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  .password {
    flex: 1;
  }
}
.modal {
  :global {
    .mantine-Modal-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
    }
    .mantine-Modal-close {
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: #0e121a;
        }
      }
    }
    .mantine-Modal-inner {
      background: #00000099;
    }
  }
}
