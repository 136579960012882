.wrapper {
  > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 2px;
    color: #0e121a;
  }
  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
}
.placeholderIcon {
  width: 48px;
  height: 48px;
  display: inline-flex;
  border-radius: 50%;
  background: #5a82fd;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  > svg {
    color: white;
    width: 24px;
    height: 24px;
  }
}
.required {
  color: #d92937;
}
