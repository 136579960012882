.wrapper {
  :global {
    .mantine-MultiSelect-rightSection {
      > svg {
        width: 20px;
        height: 20px;
        color: #0E121A;
        > path {
          stroke-width: 1;
        }
      }
    }
    .mantine-MultiSelect-required {
      color: #D92937;
    }
    .mantine-MultiSelect-itemsWrapper {
      padding: 0;
      .mantine-MultiSelect-item {
        padding: 10px 16px;
        &[data-hovered]  {
          background: #f5f7ff;
          
        }
        &[data-selected], &[data-selected][data-hovered] {
          background: #5A82FD;
        }
      }
    }
    .mantine-MultiSelect-values {
      .mantine-MultiSelect-value {
        margin: 3.5px 1px 1px 7px;
        padding-left: 12px;
        background: #FFFFFF;
        border: 1px solid #819FFD;
        border-radius: 4px;
        > span {
          color: #5A82FD;
        }
        > button {
          width: auto;
          min-width: 1px;
          margin-left: 6px;
          margin-right: 3px;
        }
        svg {
          color: #5A82FD;
        }
      }
    }
    .mantine-MultiSelect-input {
      padding-left: 8px;
    }
    .mantine-MultiSelect-searchInput {
      height: 32px;
      line-height: 32px;
    }
  }
  &.hasValue {
    :global {
      .mantine-MultiSelect-values {
        padding-bottom: 2.5px;
      }
      .mantine-MultiSelect-input {
        padding-left: 7px;
      }
    }
  }
}