.icon {
  color: #F93746;
  > svg {
    width: 48px;
    height: 48px;
  }
}
.description {
  color: #8C8C8C;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding-right: 20px;
}
.content {
  display: flex;
  align-items: center;
}
.modal {
  :global {
    .mantine-Modal-modal {
      padding: 40px;
    }
    .mantine-Modal-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
      color: #0E121A;
    }
    .mantine-Modal-close {
      > svg {
        width: 24px;
        height: 24px;
        color: #0E121A;
      }
    }
  }
  .icon {
    margin-right: 26px;
  }
}