.wrapper {
  :global {
    .mantine-Radio-body {
      cursor: pointer;
    }
    .mantine-Radio-inner {
      align-self: center;
      > input {
        width: 16px;
        height: 16px;
        cursor: pointer;
        &:checked {
          border-color: #5A82FD;
          background: white;
          + svg {
            color: #5A82FD;
            path {
              fill: #5A82FD;
            }
          }
        }
      }
    }
    .mantine-Radio-labelWrapper {
      .mantine-Radio-label {
        cursor: pointer;
        padding-left: 4px;
      }
    }
  }
}