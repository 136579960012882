.paginationSizeDropdown {
  min-width: 94px;
  padding: 0;
  border-radius: 0;
  > div {
    > button {
      padding: 0 0 0 16px;
      min-height: 22px;
      max-height: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      &:hover {
        background: #5A82FD;
        color: white;
      }
    }
  }
}
.sizeActive {
  background: #5A82FD;
  color: white;
}
.pageSizeWrapper {
  position: relative;
  .paginationSizeDropdown {
    border: 0;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
    width: auto !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 25px;
  }
}