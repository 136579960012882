.sortIcon {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  width: 14px;
}
.tableHeader {
  &.hasMultiselection {
    > tr {
      &:first-child {
        > th {
          &:first-child {
            .sortIcon {
              display: none !important;
            }
            :global {
              .mantine-Checkbox-root {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
