.navbarItem {
  :global {
    .mantine-ThemeIcon-root {
      > svg {
        width: 14px;
        height: 14px;
      }
    }
    .mantine-NavLink-root {
      padding-right: 10px;
      &:hover {
        background: #F5F7FF;
      }
      .mantine-NavLink-rightSection {
        > svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
.navButtonHasSub {
  + div {
    :global {
      .mantine-NavLink-root {
        min-height: 42px;
      }
    }
  }
  &.bold {
    font-weight: bold;
  }
}