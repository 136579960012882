.rowCursor {
  cursor: pointer;
}
.tableBody {
  td {
    :global {
      .mantine-Switch-root {
        display: flex;
      }
    }
  }
  &.selectedColor {
    tr {
      &.isSelected {
        background: #f5f7ff;
      }
    }
  }
}
