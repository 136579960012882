.wrapper {
  :global {
    .mantine-NumberInput-icon {
      color: #0E121A;
      right: 0;
      left: auto;
    }
    .mantine-Input-withIcon {
      padding-left: 12px;
      padding-right: 28px;
    }
  }
}