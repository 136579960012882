.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  p {
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    margin: 0;
    color: black;
    width: 460px;
    text-align: center;
    magin: auto;
  }
}
.button {
  margin-top: 100px;
}
