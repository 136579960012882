.tableTotal {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e121a;
  display: inline-block;
  margin-right: 12px;
  b {
    font-weight: 500;
  }
  > span {
    font-size: 14px;
  }
}
