.title {
  display: flex;
  align-items: center;
  > div {
    display: flex;
  }
}
.tabs {
  width: 100%;
}
.addressColumn {
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
    color: #0e121a;
    position: relative;
    top: 1px;
  }
}
.pagination {
  justify-content: flex-end;
  margin-top: 16px;
}
.filterDropdown {
  min-width: 94px;
  padding: 0;
  border-radius: 0;
  > div {
    > button {
      padding: 0 0 0 16px;
      min-height: 22px;
      max-height: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      &:hover {
        background: #5a82fd;
        color: white;
      }
    }
  }
}
.labelActive {
  background: #5a82fd;
  color: white;
}
.filterWrapper {
  position: relative;
  width: 145px;
  max-width: 145px;
  min-width: 145px;
  .filterDropdown {
    border: 0;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
    width: auto !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 25px;
  }
}
.balanceTab {
  table {
    thead {
      tr {
        > th {
          &:nth-child(8) {
            :global {
              .mantine-Group-root {
                position: absolute;
                top: 16px;
                right: -25px;
              }
            }
          }
        }
      }
    }
  }
}
.tabGroup {
  position: relative;
}
.apiSwitchWrapper {
  display: flex;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 0;
}
.apiSwitch {
  display: flex;
  align-items: center;
  gap: 16px;
  > div {
    display: flex;
  }
  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #0e121a;
  }
}
