.checkboxAll {
  display: flex;
  align-items: center;
  > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #0e121a;
    display: inline-block;
    margin-left: 6px;
    text-align: center;
    cursor: pointer;
  }
}
.tableWrapper {
  position: relative;
  :global {
    .mantine-Table-root {
      thead {
        tr {
          th {
            padding: 16px 22px;
            > div {
              > div {
                font-weight: 500;
              }
            }
          }
        }
      }
      tbody {
        > tr {
          &:hover {
            background: #f5f7ff;
          }
        }
      }
    }
  }
}
.loadingOverlay {
  bottom: -10px;
}
.headerRefresh {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  svg {
    color: #5a82fd;
  }
  &.isRefreshLoading {
    svg {
      animation-name: rotate;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
