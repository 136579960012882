.wrapper {
  :global {
    .mantine-Select-rightSection {
      > svg {
        width: 20px;
        height: 20px;
        color: #0E121A;
        > path {
          stroke-width: 1;
        }
      }
    }
    .mantine-Select-required {
      color: #D92937;
    }
    .mantine-Select-itemsWrapper {
      padding: 0;
      .mantine-Select-item {
        padding: 10px 16px;
        &[data-hovered]  {
          background: #f5f7ff;
          
        }
        &[data-selected], &[data-selected][data-hovered] {
          background: #5A82FD;
        }
      }
    }
  }
}