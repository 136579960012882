@import 'src/assets/scss/mixins';

:global {
  .mantine-ScrollArea-viewport {
    overflow-x: hidden !important;
  }
  .mantine-ScrollArea-scrollbar[data-orientation='horizontal'] {
    display: none !important;
  }
}

.merchantTitleWrapper {
  display: flex;
  align-items: center;
  max-width: calc(100% - 300px);
  :global {
    .mantine-Chip-root {
      margin-left: 24px;
      .mantine-Chip-label {
        border-color: #228be6;
        color: #228be6;
        font-weight: 700;
      }
    }
    .mantine-Text-root {
      @include ellipsisLongText();
    }
  }
}
.poErrorMsg {
  color: #d92937;
  font-size: 14px;
  font-weight: bold;
  margin: -10px 0;
}
.fileLists {
  margin-top: 24px;
}
.uploadedFileItem {
  background: #fbfbfd;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 14px 20px 14px 24px;
  position: relative;
  .fileName {
    > div {
      &:nth-child(1) {
        font-weight: 500;
        color: #3e444f;
      }
      &:nth-child(2) {
        font-size: 12px;
        font-weight: 500;
        color: #9d9ea1cc;
        line-height: 22px;
      }
    }
  }
  .fileAction {
    > div {
      height: 36px;
      width: 1px;
      border-left: 1px solid #696a72;
    }
    > svg,
    > span {
      cursor: pointer;
    }
    .eyeIcon {
      width: 32px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
    }
    &.fileActionIsView {
      .eyeIcon {
        background: #5a82fd;
        > svg {
          stroke: white;
        }
      }
    }
  }
  &.itemIsView {
    background: #f5f7ff;
  }
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.csvPreview {
  width: calc(100vw - 460px);
  max-width: calc(100vw - 460px);
  min-width: calc(100vw - 460px);
  overflow: auto;
  max-height: 600px;
}
.closeFileDetails {
  color: #5a82fd;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: underline;
    cursor: pointer;
  }
}
.isActiveWrapper {
  :global {
    .mantine-Switch-root {
      display: flex;
      align-items: center;
    }
  }
}
