.wrapper {
  thead {
    position: sticky;
    top: 0;
    tr {
      background: #F1F1F1;
      th {
        border-right: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #262626;
        height: 58px;
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #262626;
        background: white;
        border-color: #D9D9D9;
        &:first-child {
          border-left: 1px solid #D9D9D9;
        }
        &:last-child {
          border-right: 1px solid #D9D9D9;
        }
      }
      &:hover {
        td {
          background: rgba(230, 247, 255, 0.4);
        }
      }
    }
  }
}