.inner {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  gap: 24px;
  :global {
    .mantine-Paper-root {
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100vh - 400px);
    }
    .mantine-Table-root {
      margin-bottom: 0;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.13);
  position: absolute;
  bottom: -30px;
  left: -30px;
  right: -30px;
  padding: 24px;
  > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0e121a;
  }
}
.title {
  display: flex;
  flex-direction: column;
  color: black;
  gap: 24px;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
}
.closeButton {
  display: flex;
  justify-content: flex-end;
}
